<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="bannerbox">
      </div>

       <div class="centerbox">
      <el-menu router :active="$route.path" class="el-menu-demo" mode="horizontal">
        <el-menu-item :index='("/search/searchbook?wd="+wd).toString()'>图书</el-menu-item>
        <el-menu-item  :index='("/search/searchcolumn?wd="+wd).toString()'>专栏</el-menu-item> 
      </el-menu>
      
      <router-view></router-view>
      </div>
       <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
export default {
  components: {
    NavMain,
    FooterAll
  }, 
  computed: {
    wd() {
      return this.$route.query.wd || '';
    },
  }
};
</script>
<style lang="less" scoped>
.el-container {
  height: 100%;
  flex-direction: column;
  .el-container {
  }
  .el-main {
    padding: 0;
  }
}

.bannerbox {
  height: 90px;
  background: url("../../assets/imgs/webimg/aboutbannerbg.png") no-repeat;
  background-size: 100% 90px;
  box-sizing: border-box;
  .titp {
    font-size: 12px;
    color: #2f95e3;
  }
  .tittleh2 {
    font-size: 20px;
    color: #2f95e3;
    margin: 22px 0 5px;
    font-weight: bold;
  }
}
.centerbox {
  width: 1200px;
  margin: 0 auto;
  padding:20px 0;
  box-sizing: border-box;

}
.container {
  width: 1200px;
  margin: 0 auto;
  padding-left: 95px;
  overflow: hidden;
  box-sizing: border-box;
}
.aboutcontent {
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;
  .iconfont {
    color: #23b7e5;
    font-size: 24px;
    margin-right: 10px;
    float: left;
  }
  dt {
    width: 88px;
  }
  dd {
    flex: 1;
  }
}
/deep/.el-menu--horizontal.el-menu-demo {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 16px;
    color: #222;
    margin-right: 20px;
    &.is-active {
      color: #16aed0;
    }
  }
}
</style>